import React, { useState } from 'react';
import { ReactComponent as DeveloperEventIcon } from '../../../static/v4/DeveloperEventIcon.svg';
import MadalaIcon from '../../../static/v4/madala.jpg';
import CardOneTwoIcon from '../../../static/v4/card-1-2.jpg';
import CardTwoOneIcon from '../../../static/v4/card-2-1.jpg';
import CardTwoTwoIcon from '../../../static/v4/card-2-2.jpg';
import CardTwoThreeIcon from '../../../static/v4/card-2-3.jpg';
import CardTwoFourIcon from '../../../static/v4/card-2-4.jpg';
import CardThreeOneIcon from '../../../static/v4/card-3-1.jpg';
import CardThreeTwoIcon from '../../../static/v4/card-3-2.jpg';
import CardThreeThreeIcon from '../../../static/v4/card-3-3.jpg';
import CardThreeFourIcon from '../../../static/v4/card-3-4.jpg';
import CardFourOneIcon from '../../../static/v4/card-4-1.jpg';
import CardFourTwoIcon from '../../../static/v4/card-4-2.jpg';
import CardFourThreeIcon from '../../../static/v4/card-4-3.jpg';
import CardFourFourIcon from '../../../static/v4/card-4-4.jpg';
import { ReactComponent as RightIcon } from '../../../static/v4/cheveron-right.svg';

import './index.scss';

type cardItem = {
  img: any;
  label: string;
  link: string;
};

export function DevelopersEventHighlights() {
  const cardsConfig: cardItem[][] = [
    [
      {
        label: 'Starknet & Madara AMA',
        link: 'https://twitter.com/Starknet/status/1716378934529474929',
        img: MadalaIcon,
      },
      {
        label: 'STARKNET ECOSYSTEM MEETUP',
        link: 'https://twitter.com/Web3MQ/status/1712348579288031690',
        img: CardOneTwoIcon,
      },
    ],
    [
      {
        label: 'L3 / Appchains Workshop',
        link: 'https://twitter.com/Web3MQ/status/1722951466514612715',
        img: CardThreeOneIcon,
      },
      {
        label: 'China First STARKNET WORKSHOP',
        link: 'https://twitter.com/StarkNetAstro/status/1669510103811358721',
        img: CardTwoOneIcon,
      },
      {
        label: 'Hackathon@Tokyo',
        link: 'https://twitter.com/Web3MQ/status/1645795726830768130',
        img: CardTwoTwoIcon,
      },
      {
        label: 'Hackathon@HongKong',
        link: 'https://twitter.com/Web3MQ/status/1645262231066796032',
        img: CardTwoThreeIcon,
      },
    ],
    [
      {
        label: 'Account Abstraction HackerHouse with Antalpha',
        link: 'https://lu.ma/re80x2ch',
        img: CardTwoFourIcon,
      },
      {
        label: 'Web3MQ Snap has now joined METAMASK Snaps',
        link: 'https://twitter.com/zerokn0wledge_/status/1704105361677185027',
        img: CardThreeTwoIcon,
      },
      {
        label: 'BNB Zero2Hero Hackathon',
        link: 'https://www.bnbchain.org/en/blog/winners-announcement-zero2hero-hackathon',
        img: CardThreeThreeIcon,
      },
      {
        label: 'Account Abstraction HackerHouse with Antalpha',
        link: 'https://threadreaderapp.com/thread/1647949252096839680.html',
        img: CardThreeFourIcon,
      },
    ],
    [
      {
        label: 'Mask & Matchbox Hackathon',
        link: 'https://the-network-state-hackathon.devfolio.co/projects',
        img: CardFourOneIcon,
      },
      {
        label: 'Notfellows Tokyo',
        link: 'https://twitter.com/notfellows/status/1645752724938711046',
        img: CardFourTwoIcon,
      },
      {
        label: 'AHH on-chain gaming',
        link: 'https://threadreaderapp.com/thread/1647949252096839680.html',
        img: CardFourThreeIcon,
      },
      {
        label: 'United Hacker House',
        link: 'https://twitter.com/MinaProtocol/status/1645797043426963469',
        img: CardFourFourIcon,
      },
    ],
  ];

  return (
    <main id="developersEvent" className="developersEventHighlights">
      <div className="right-icon">
        <DeveloperEventIcon />
      </div>
      <div className="header">Developers Event Highlights</div>
      <div className="cards">
        {cardsConfig.map((item, index) => {
          return (
            <div key={`1_${index}`} className="cards-column">
              {item.map((itemB, indexB) => {
                return (
                  <div
                    className={
                      item.length > 2
                        ? `cardItemBox cardItemBoxSmall`
                        : 'cardItemBox'
                    }
                    key={`2_${indexB}`}
                  >
                    <img src={itemB.img} alt="" />

                    <a href={itemB.link} target="_blank">
                      <div className="a-text">{itemB.label}</div>
                      <RightIcon />
                    </a>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </main>
  );
}
