import React from 'react';

import HeadNav from '../HeadNav';

import './index.scss';
import { ReactComponent as NomosLogoIcon} from '../../../static/v4/NomosLogoIcon.svg'
import { ReactComponent as NomosMobileLogoIcon} from '../../../static/v4/NomosMobileLogoIcon.svg'

export type IPrpos = {
  appType: string;
  onClick: () => void;
};
const Header = (props: IPrpos) => {
  const { appType, onClick } = props;
  const handleLink = (type: string) => {
    if (type === 'build') {
      window.open('https://docs.web3mq.com/docs/Web3MQ-SDK/JS-SDK/quickStart/');
    } else if (type === 'docs') {
      window.open('https://docs.web3mq.com/docs/Intro');
    }
  };

  return (
    <header className="headerContainer">
      <HeadNav appType={appType} visible={false} onClick={onClick} />
      <div className="headerMainBox">
        {
          appType === 'pc' ? <NomosLogoIcon /> :  <NomosMobileLogoIcon />
        }

        {/*<div className="titleTag">NOMOS NETWORK</div>*/}
        <div className="title">
          Your Gateway to Mass Adoption
          <div className='sub-title'>
            The next-generation modular L3/appchain framework
          </div>
        </div>
        <div className="buttonGroup">
          <div className="lef-btn">
            <button className="buildButton" onClick={() => handleLink('build')}>
              Get Started →
            </button>
            <div className='btn-desc'>
              First appchain powered by Nomos
            </div>
          </div>

          <button
            className="readDocButton"
            onClick={() =>
              window.open('https://www.notion.so/s3labs/NomosNetwork-and-the-Next-Generation-Application-Chain-Architecture-be102cabc3024ec9aff7fa0f925dac8c', '_blank')
            }
          >
            Introduction
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
