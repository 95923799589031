import React from 'react';

export const TwitterIcon = (props: any) => {
  return (
    <div {...props}>    
      <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
          fillRule="evenodd" 
          clipRule="evenodd" 
          d="M19.5832 2.39593C18.8784 2.71654 18.1196 2.93332 17.3246 3.03144C18.1365 2.53171 18.76 1.74217 19.0532 0.800879C18.2932 1.26297 17.452 1.59841 16.5556 1.77868C15.8385 0.994841 14.8169 0.505371 13.6859 0.505371C11.5142 0.505371 9.7529 2.31036 9.7529 4.5375C9.7529 4.85469 9.78898 5.16161 9.85551 5.45712C6.58777 5.28939 3.69101 3.68293 1.75156 1.24357C1.41216 1.83915 1.21934 2.53171 1.21934 3.27104C1.21934 4.66986 1.91393 5.90437 2.96823 6.62659C2.32325 6.60606 1.71661 6.42464 1.18777 6.12229V6.17363C1.18777 8.12695 2.542 9.75623 4.34162 10.127C4.01124 10.2195 3.66507 10.2685 3.30537 10.2685C3.05279 10.2685 2.80585 10.2434 2.56568 10.1955C3.06633 11.7974 4.51865 12.9635 6.23935 12.9965C4.89301 14.077 3.19712 14.7217 1.35465 14.7217C1.0378 14.7217 0.724334 14.7034 0.416504 14.6658C2.15637 15.8101 4.22323 16.4776 6.44457 16.4776C13.6769 16.4776 17.6325 10.3336 17.6325 5.0053C17.6325 4.82959 17.6279 4.6573 17.6212 4.48388C18.3891 3.91568 19.0566 3.20487 19.5832 2.39707V2.39593Z" 
          fill="#18181B"
        />
      </svg>
    </div>
  )
}