import React, {useCallback, useEffect, useState} from 'react';
import cx from 'classnames';
import partners1 from '../../../static/v4/scroll-1.svg';
import partners1Mobile from '../../../static/v4/scroll-1.jpg';
import partners2 from '../../../static/v4/scorll-2.svg';
import partners2Mobile from '../../../static/v4/scorll-2.jpg';

import './index.scss';

export function Partners() {
    const [appType, setAppType] = useState<string>(
        window.innerWidth <= 768 ? 'h5' : 'pc'
    );

    useEffect(() => {
        window.addEventListener('resize', () => {
            setAppType(window.innerWidth <= 768 ? 'h5' : 'pc');
        });
    }, []);
  const Title = useCallback(
    (props: {
      column?: boolean;
      id?: string;
      subTitle?: string;
      title: string;
      tag?: string;
    }) => {
      const { column, id, subTitle, title, tag } = props;
      return (
        <div
          id={id}
          className={cx('commonTitleContainer', {
            column: column,
          })}
        >
          <div className="title">{title}</div>
          {tag && <div className="tag">{tag}</div>}
          {subTitle && <div className="subTitle">{subTitle}</div>}
        </div>
      );
    },
    []
  );
  return (
    <section className="partners" id="partnership">
      <Title
        column
        subTitle="Powering the world’s best product teams."
        title="Partners & Supporters"
      />
      <div className="partnerList">
        <div className="partner-cover-left"></div>
        <div className="partnerWrapper start">
          <div className="animationScroll">
            <img src={appType === 'pc' ? partners1 : partners1Mobile} alt="" className="partnerImg" />
          </div>
          <div className="animationScroll">
            <img src={appType === 'pc' ? partners1 : partners1Mobile} alt="" className="partnerImg" />
          </div>
        </div>
        <div className="partnerWrapper reverse">
          <div className="animationScroll">
            <img src={appType === 'pc' ? partners2 : partners2Mobile} alt="" className="partnerImg" />
          </div>
          <div className="animationScroll">
            <img src={appType === 'pc' ? partners2 : partners2Mobile} alt="" className="partnerImg" />
          </div>
        </div>
        <div className="partner-cover-right"></div>
      </div>
    </section>
  );
}