import React, { useEffect, useMemo, useState } from 'react';
import CardOneOneIcon from '../../../static/v4/ethosChain-1-1.jpg';
import CardTwoOneIcon from '../../../static/v4/ethosChain-2-1-web.jpg';
import CardTwoOneMobileIcon from '../../../static/v4/ethosChain-2-1-mobile.jpg';
import CardTwoTwoIcon from '../../../static/v4/ethosChain-2-2.jpg';

import './index.scss';

type cardItem = {
  img: any;
  label: string;
  link: string;
  desc: string;
};

export function EthosChain() {
  const [appType, setAppType] = useState<string>(
    window.innerWidth <= 768 ? 'h5' : 'pc'
  );

  useEffect(() => {
    window.addEventListener('resize', () => {
      setAppType(window.innerWidth <= 768 ? 'h5' : 'pc');
    });
  }, []);

  const cardsConfig = useMemo(() => {
    return [
      {
        label:
          'User-centric Support for Push, Group Chat, Communication, and More',
        link: 'https://snaps.metamask.io/snap/npm/web3mq/snap/',
        img: CardOneOneIcon,
        desc: 'Seamless collaboration across diverse application use cases like Push, DMs, and Group chat, along with compatibility with existing solutions, widens communication networks for better scalability.',
      },
      {
        label: 'Fastest & Most Scalable in Web3',
        link: 'https://snaps.metamask.io/snap/npm/web3mq/snap/',
        img: appType === 'pc' ? CardTwoOneIcon : CardTwoOneMobileIcon,
        desc:
          'The Ethos chain runs the fastest and most scalable Web3 messaging service to date.\n' +
          'there exists the fastest and most scalable Web3 messaging relay network, which is also the most flexible and offers optimal scalability, while already supporting ActivityPub.',
      },
      {
        label: 'Based on State Channel Architecture',
        link: 'https://realms.world/',
        img: CardTwoTwoIcon,
        desc: 'Ethos is built using a Web3 native state channel architecture provided by Nomos, allowing Ethos to provide not only trustless messaging, but also trustless interactions with L1/L2s.',
      },
    ];
  }, [appType]);

  const jumpToDoc = () => {
    window.open('https://docs.web3mq.com/docs/Intro');
  };

  const jumpToChannel = () => {
    window.open('https://docs.web3mq.com/docs/Ethos-SDK/JS-SDK/channel/');
  };

  return (
    <main id="ethosChain" className="ethosChain">
      <div className="header">Ethos Chain</div>
      <div className="subTitle">
        Ethos is a StarkNet L3 appchain built for trustless messaging, social
        applications, and gaming communities. Ethos ensures reliability and
        security by leveraging distributed nodes, eliminating reliance on a
        single entity and guarding against censorship or data abuse.
      </div>
      <div className="cards">
        {cardsConfig.map((itemB, indexB) => {
          return (
            <div key={`1_${indexB}`} className="cards-column">
              <div className="cardItemBox" style={indexB === 0 ? { paddingBottom: 0 } : {}}>
                {indexB === 1 && appType !=='h5' && <img src={itemB.img} alt="" />}
                <div className="card-bottom" style={indexB === 0 ? { paddingBottom: '36px', paddingTop: '0px' } : {}}>
                  <div className="topTitle">
                    <div className="title">{itemB.label}</div>
                  </div>
                  <div className="bottomDesc">{itemB.desc}</div>
                  {indexB === 0 && (
                    <div className="btnGroup">
                      <button onClick={jumpToDoc}>Push</button>
                      <button onClick={jumpToChannel}>DM</button>
                      <button onClick={jumpToChannel}>Group Chat</button>
                    </div>
                  )}
                </div>
                {appType === 'h5' ? (
                  <img src={itemB.img} alt="" />
                ) : (
                  indexB !== 1 && <img style={indexB === 0 ? {maxWidth: '42%'} : {}} src={itemB.img} alt="" />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </main>
  );
}
