import React from 'react';

export const GitHubIcon = (props: any) => {
  return (
    <div {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
          fillRule="evenodd" 
          clipRule="evenodd" 
          d="M8 0C3.5816 0 0 3.5872 0 8.0136C0 11.5536 2.292 14.5576 5.4712 15.6168C5.8712 15.6904 6.0168 15.4432 6.0168 15.2304C6.0168 15.0408 6.0104 14.536 6.0064 13.868C3.7808 14.352 3.3112 12.7936 3.3112 12.7936C2.948 11.8672 2.4232 11.6208 2.4232 11.6208C1.6968 11.1248 2.4784 11.1344 2.4784 11.1344C3.2808 11.1904 3.7032 11.96 3.7032 11.96C4.4168 13.184 5.576 12.8304 6.0312 12.6256C6.1048 12.108 6.3112 11.7552 6.54 11.5552C4.764 11.3528 2.896 10.6648 2.896 7.5944C2.896 6.72 3.208 6.004 3.7192 5.444C3.6368 5.2416 3.3624 4.4264 3.7976 3.324C3.7976 3.324 4.4696 3.108 5.9976 4.1448C6.65021 3.96681 7.32355 3.87615 8 3.8752C8.68 3.8784 9.364 3.9672 10.0032 4.1448C11.5304 3.108 12.2008 3.3232 12.2008 3.3232C12.6376 4.4264 12.3624 5.2416 12.2808 5.444C12.7928 6.004 13.1032 6.72 13.1032 7.5944C13.1032 10.6728 11.232 11.3504 9.4504 11.5488C9.7376 11.796 9.9928 12.2848 9.9928 13.0328C9.9928 14.1032 9.9832 14.968 9.9832 15.2304C9.9832 15.4448 10.1272 15.6944 10.5336 15.616C12.1266 15.0817 13.5115 14.0602 14.4924 12.696C15.4733 11.3318 16.0007 9.69385 16 8.0136C16 3.5872 12.4176 0 8 0Z" 
          fill="#18181B"
        />
      </svg>
    </div>
  )
}