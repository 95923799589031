import React, { useEffect, useState } from 'react';

const WindowProvider = (props: { children: React.ReactNode}) => {
  const { children } = props;
  const [isBrowser, setIsBrowser] = useState<boolean>(false);
  useEffect(() => {
    setIsBrowser(!!window);
  }, []);

  return (
    <>
      {isBrowser && children}
    </>
  )
};
export default WindowProvider;