import React, { useState } from 'react';
import CardOneOneIcon from '../../../static/v4/ecosystem-1-1.jpg';
import CardOneTwoIcon from '../../../static/v4/ecosystem-1-2.jpg';
import CardTwoOneIcon from '../../../static/v4/ecosystem-2-1.jpg';
import CardTwoTwoIcon from '../../../static/v4/ecosystem-2-2.jpg';
import CardTwoThreeIcon from '../../../static/v4/ecosystem-2-3.jpg';
import CardIconOneOneIcon from '../../../static/v4/ecosystem-icon-1-1.svg';
import CardIconOneTwoIcon from '../../../static/v4/ecosystem-icon-1-2.svg';
import CardIconTwoOneIcon from '../../../static/v4/ecosystem-icon-2-1.svg';
import CardIconTwoTwoIcon from '../../../static/v4/ecosystem-icon-2-2.svg';
import CardIconTwoThreeIcon from '../../../static/v4/ecosystem-icon-2-3.svg';
import { ReactComponent as WebsiteShareIcon } from '../../../static/v4/websiteShare.svg';
import { ReactComponent as XShareIcon } from '../../../static/v4/XShreIcon.svg';
import EcosystemBtnTagIcon from '../../../static/v4/ecosystemBtnTag.svg';

import './index.scss';

type cardItem = {
  img: any;
  label: string;
  link?: string;
  XLink?: string;
  icon: any;
  desc: string;
};

export function NomosEcosystem() {
  const cardsConfig: cardItem[][] = [
    [
      {
        label: 'Web3MQ Snap',
        link: 'https://snaps.metamask.io/snap/npm/web3mq/snap/',
        img: CardOneOneIcon,
        icon: CardIconOneOneIcon,
        desc: 'Web3-native decentralized communication protocol. Encrypted, efficient and borderless.',
      },
      {
        label: 'Realms.connect',
        link: 'https://realms.world/',
        XLink: 'https://twitter.com/lootrealms?lang=en',
        img: CardOneTwoIcon,
        icon: CardIconOneTwoIcon,
        desc: 'Building holistic social and community infrastructure for realms.world, coming soon',
      },
    ],
    [
      {
        label: 'ZK-Werewolf ',
        img: CardTwoOneIcon,
        icon: CardIconTwoOneIcon,
        desc: 'Fully-on-chain social game',
      },
      {
        label: 'Tatsu',
        link: 'https://tatsu.social/profile',
        img: CardTwoTwoIcon,
        icon: CardIconTwoTwoIcon,
        desc: 'Web3 Native Reach and Push Apps',
      },
      {
        label: 'SwapChat',
        XLink: 'https://twitter.com/SwapChatNFT',
        img: CardTwoThreeIcon,
        icon: CardIconTwoThreeIcon,
        desc: 'Web3 Native Cross-Platform Chat dApp',
      },
    ],
  ];

  return (
    <main id="nomosEcosystem" className="nomosEcosystem">
      <div className="header">Nomos Ecosystem</div>
      <div className="titleTag">Dapps on Ecosystem appchains</div>
      <div className="cards">
        {cardsConfig.map((item, index) => {
          return (
            <div key={`1_${index}`} className="cards-column">
              {item.map((itemB, indexB) => {
                return (
                  <div
                    className={
                      item.length > 2
                        ? `cardItemBox cardItemBoxSmall`
                        : 'cardItemBox'
                    }
                    key={`2_${indexB}`}
                  >
                    <img src={EcosystemBtnTagIcon} className='ecosystemBtnTagIcon' alt=""/>
                    <img src={itemB.img} alt="" />
                    <div className="card-bottom">
                      <div className="topTitle">

                        <img src={itemB.icon} className="webIcon" alt="" />
                        <div className="title">{itemB.label}</div>
                        {itemB.XLink && (
                          <a href={itemB.XLink} target={'_blank'}>
                            <XShareIcon />
                          </a>
                        )}
                        {itemB.link && (
                          <a href={itemB.link} target={'_blank'}>
                            <WebsiteShareIcon />
                          </a>
                        )}
                      </div>
                      <div className="bottomDesc">{itemB.desc}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </main>
  );
}
