import React from 'react';

export const MenuIcon = (props: any) => {
  return (
    <div {...props}>
      <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
          fillRule="evenodd" 
          clipRule="evenodd" 
          d="M0 1C0 0.447715 0.447715 0 1 0V1V2C0.447715 2 0 1.55228 0 1ZM1 1V2H13C13.5523 2 14 1.55228 14 1C14 0.447715 13.5523 0 13 0H1.00105L1 1ZM0 7C0 6.44772 0.447715 6 1 6H17C17.5523 6 18 6.44772 18 7C18 7.55228 17.5523 8 17 8H1C0.447715 8 0 7.55228 0 7ZM0 13C0 12.4477 0.447715 12 1 12V13V14C0.447715 14 0 13.5523 0 13ZM1 13V14H9C9.55228 14 10 13.5523 10 13C10 12.4477 9.55228 12 9 12H1V13Z" 
          fill="#18181B"
        />
      </svg>
    </div>
  )
}