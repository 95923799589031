import React, { useEffect, useState } from 'react';

import './index.scss';
import NomoIcon from '../../../static/v4/nomoImg.png';
import NomoMobileIcon from '../../../static/v4/nomoMobileImg.png';

export function ThrivingLandscape() {
  const [appType, setAppType] = useState<string>(
    window.innerWidth <= 768 ? 'h5' : 'pc'
  );

  useEffect(() => {
    window.addEventListener('resize', () => {
      setAppType(window.innerWidth <= 768 ? 'h5' : 'pc');
    });
  }, []);

  return (
    <main id="thrivingLandscape" className="thrivingLandscape">
      <div className="header">Thriving Landscape</div>
      <div className="subTitle">A Vision of Growth</div>
      <img src={ appType === 'pc' ? NomoIcon : NomoMobileIcon } alt="" />
    </main>
  );
}
