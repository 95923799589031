import React from 'react';

export const RightIcon = (props: any) => {
  return (
    <div {...props}>
      <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
          fillRule="evenodd" 
          clipRule="evenodd" 
          d="M0.910826 0.577452C1.23626 0.252015 1.7639 0.252015 2.08934 0.577452L7.92267 6.41078C8.07895 6.56706 8.16675 6.77903 8.16675 7.00004C8.16675 7.22105 8.07895 7.43302 7.92267 7.5893L2.08934 13.4226C1.7639 13.7481 1.23626 13.7481 0.910826 13.4226C0.585389 13.0972 0.585389 12.5696 0.910826 12.2441L6.1549 7.00004L0.910826 1.75596C0.585389 1.43053 0.585389 0.902889 0.910826 0.577452Z" 
          fill="#D4D4D8"
        />
      </svg>
    </div>
  )
}