import React from 'react';

export const DiscordIcon = (props: any) => {
  return (
    <div {...props}>
      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
          d="M16.9308 1.65186C15.6561 1.06696 14.2892 0.63603 12.8599 0.389219C12.8339 0.384456 12.8079 0.39636 12.7945 0.420168C12.6187 0.732847 12.4239 1.14076 12.2876 1.46138C10.7503 1.23124 9.22099 1.23124 7.71527 1.46138C7.57887 1.13364 7.37707 0.732847 7.20048 0.420168C7.18707 0.397154 7.16107 0.38525 7.13504 0.389219C5.70659 0.635241 4.33963 1.06617 3.06411 1.65186C3.05307 1.65662 3.04361 1.66457 3.03732 1.67488C0.444493 5.54851 -0.265792 9.32693 0.0826501 13.0585C0.0842267 13.0768 0.0944749 13.0942 0.108665 13.1053C1.81934 14.3616 3.47642 15.1243 5.10273 15.6298C5.12876 15.6377 5.15634 15.6282 5.1729 15.6068C5.55761 15.0814 5.90054 14.5275 6.19456 13.945C6.21192 13.9108 6.19535 13.8704 6.15989 13.8569C5.61594 13.6505 5.098 13.399 4.59977 13.1133C4.56037 13.0903 4.55721 13.0339 4.59347 13.0069C4.69831 12.9283 4.80318 12.8466 4.9033 12.7641C4.92141 12.749 4.94665 12.7458 4.96794 12.7553C8.24107 14.2497 11.7846 14.2497 15.0191 12.7553C15.0404 12.745 15.0657 12.7482 15.0846 12.7633C15.1847 12.8458 15.2895 12.9283 15.3952 13.0069C15.4314 13.0339 15.4291 13.0903 15.3897 13.1133C14.8914 13.4045 14.3735 13.6505 13.8288 13.8561C13.7933 13.8696 13.7775 13.9108 13.7949 13.945C14.0952 14.5267 14.4381 15.0806 14.8157 15.606C14.8315 15.6282 14.8599 15.6377 14.8859 15.6298C16.5201 15.1243 18.1772 14.3616 19.8879 13.1053C19.9028 13.0942 19.9123 13.0776 19.9139 13.0593C20.3309 8.74519 19.2154 4.99775 16.9568 1.67566C16.9513 1.66457 16.9419 1.65662 16.9308 1.65186ZM6.68335 10.7864C5.69792 10.7864 4.88594 9.88166 4.88594 8.77059C4.88594 7.65952 5.68217 6.75482 6.68335 6.75482C7.69239 6.75482 8.49651 7.66747 8.48073 8.77059C8.48073 9.88166 7.68451 10.7864 6.68335 10.7864ZM13.329 10.7864C12.3435 10.7864 11.5316 9.88166 11.5316 8.77059C11.5316 7.65952 12.3278 6.75482 13.329 6.75482C14.338 6.75482 15.1421 7.66747 15.1264 8.77059C15.1264 9.88166 14.338 10.7864 13.329 10.7864Z" 
          fill="#18181B"
        />
      </svg>
    </div>
  )
}