import React from 'react';

import { bottomNavigation } from '../HeadNav';
import {
  DiscordIcon,
  GitHubIcon,
  TwitterIcon,
  Web3MQLogoIcon,
  TelegramIcon,
} from '../../icons';

import './index.scss';

const BADGE_ID = 'ceba27eb35e71b35';

const ALCHEMY_ANALYTICS_URL = `https://analytics.alchemyapi.io/analytics`;
const ALCHEMY_URL = `https://alchemyapi.io/?r=badge:${BADGE_ID}`;

function logBadgeClick() {
  fetch(`${ALCHEMY_ANALYTICS_URL}/badge-click`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      badge_id: BADGE_ID,
    }),
  });
  // @ts-ignore
  window.open(ALCHEMY_URL, '_blank').focus();
}

function logBadgeView() {
  try {
    fetch(`${ALCHEMY_ANALYTICS_URL}/badge-view`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        badge_id: BADGE_ID,
      }),
    });
  } catch (e) {
    console.log(e);
  }
}

function isBadgeInViewpoint(bounding: any) {
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
}

var intervalId = setInterval(() => {
  const badge = document.getElementById('badge-button');
  if (badge && isBadgeInViewpoint(badge.getBoundingClientRect())) {
    logBadgeView();
    clearInterval(intervalId);
  }
}, 2000);

const links = [
  // {
  //   icon: TelegramIcon,
  //   href: 'https://docs.web3mq.com/docs/Intro',
  //   name: 'telegram',
  //   current: false,
  // },
  {
    icon: TwitterIcon,
    href: 'https://twitter.com/Web3MQ',
    name: 'twitter',
    current: false,
  },
  {
    icon: DiscordIcon,
    href: 'https://discord.com/invite/NYZaHxbE3s',
    name: 'discord',
    current: false,
  },
  {
    icon: GitHubIcon,
    href: 'https://github.com/Generative-Labs',
    name: 'github',
    current: false,
  },
];

const Footer = () => {
  return (
    <div className="footerContainer">
      <div className="footerTop">
        <Web3MQLogoIcon className="web3MqLogoIcon" />
        <div className="footerLinks">
          {bottomNavigation.map(nav => (
            <a
              className="footerLink"
              href={nav.href}
              key={nav.name}
              target={!nav.current ? '_blank' : undefined}
            >
              {nav.name}
            </a>
          ))}
        </div>
      </div>
      <div className="footerBottom">
        <div className="ThirdPartyLinks">
          {links.map(link => (
            <a href={link.href} key={link.name} target="_blank">
              <link.icon key={link.name} to={link.href} />
            </a>
          ))}
        </div>
        <div className="rightsStatment">
          <a href="#" className="alchemy">
            <img
              onClick={logBadgeClick}
              id="badge-button"
              src="https://static.alchemyapi.io/images/marketing/badgeLight.png"
              alt="Alchemy Supercharged"
            />
          </a>
          <div className="seprator" />
          <div className="footerRights">2023 Web3MQ All rights reserved.</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
