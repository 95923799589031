import React from 'react';
import Layout from '../components/Layout';
import { DevelopersEventHighlights } from '../containers/DevelopersEventHighlights';
import {NomosEcosystem} from '../containers/NomosEcosystem';
import {EthosChain} from '../containers/EthosChain';
import {ThrivingLandscape} from '../containers/ThrivingLandscape';
import { Partners } from '../containers/Partners';

import WindowProvider from '../components/WinowProvider';
import SEO from '../components/Seo';

import './index.scss';

const Web3MQ = () => {

  return (
    <WindowProvider>
      <SEO>
        <Layout>
          <ThrivingLandscape />
          <EthosChain />
          <NomosEcosystem />
          <DevelopersEventHighlights />
          <div className="contentContainer" style={{ padding: 0 }}>
            <Partners />
          </div>
        </Layout>
      </SEO>
    </WindowProvider>
  );
};

export default Web3MQ;
