import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { navigate } from "gatsby"

import HeadNav, { leftNavigation as NavigationList }  from '../HeadNav';

import { RightIcon } from '../../icons';

import './index.scss';
export type IPrpos = {
  visible: boolean;
  onClose: () => void;
}
const SideNavBar = React.memo((props: IPrpos) => {
  const { visible, onClose } = props;
  const [active, setActive] = useState<boolean>(false);
  const [aniClassName, setAniClassName] = useState<string>('');
  const bodyOverflow = useRef('visible');
  const onTransitionEnd = () => {
    setAniClassName(visible ? 'enterDone' : 'exitDone');
    if (!visible) {
      setActive(false);
    }
  };

  const handleLink = (type: string, href: string) => {
    if (type === 'Community') {
      onClose();
      navigate(href);
      return;
    }
    window.open(href);
  }
  useEffect(() => {
    bodyOverflow.current = window.getComputedStyle(document.body).overflow;
  }, [])
  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden';
      setActive(true);
      setAniClassName('enter');
      setTimeout(() => {
        setAniClassName('enterActive');
      });
    } else {
      document.body.style.overflow = bodyOverflow.current;
      setAniClassName('exit');
      setTimeout(() => {
        setAniClassName('exitActive');
      });
    }
    return () => {
      document.body.style.overflow = bodyOverflow.current;
    };
  }, [visible]);

  if (!visible && !active) {
    return null;
  }
  return (
    <div 
      className={cx('sideNavBarContainer', aniClassName)}
      onTransitionEnd={onTransitionEnd}
    >
      <HeadNav appType='h5' style={{padding: '16px 20px'}} visible={true} onClick={onClose} />
      <div className='sideNavBarItems'>
        {NavigationList.map(nav => (
          <div className='sideNavBarItem' key={nav.name} onClick={() => handleLink(nav.name, nav.href)}>
            <div className='navTitle'>{nav.name}</div>
            <RightIcon />
          </div>
        ))}
      </div>
    </div>
  )
});
export default SideNavBar;